import React, { useEffect, useState } from "react"

const STORAGE_KEY = "joulu_userstate"

type UserContext = {
  opened: number[]
  addOpen: (dayNum: number) => void
  hasOpen: (dayNum: number) => boolean
}

function getInitialState() {
  const raw = localStorage.getItem(STORAGE_KEY)
  const initialState = raw ? JSON.parse(raw) : []

  return initialState
}

export const UserContext = React.createContext<UserContext>({ opened: [], hasOpen: n => false, addOpen: n => {} })

const UserContextProvider = (props: { children: React.ReactNode }) => {
  const [opened, setOpened] = useState<number[]>([1, 2, 3])

  /**
   * Is given number in UserContext as "opened"
   */
  const hasOpen = (dayNum: number) => {
    // console.debug("UserContextProvider hasOpen", dayNum)
    return opened && opened.includes(dayNum)
  }

  const addOpen = (dayNum: number) => {
    // console.debug("UserContextProvider addOpen", dayNum)
    if (!hasOpen(dayNum)) {
      // console.debug("UserContextProvider addOpen adding", dayNum)
      setOpened([...opened, dayNum])
    }
    // console.debug("UserContextProvider addOpen skipping", dayNum)
  }

  useEffect(() => {
    const initialState = getInitialState()
    // console.debug("UserContextProvider initialState:", initialState)
    setOpened(initialState.opened || [])
  }, [])

  useEffect(() => {
    // console.debug("UserContextProvider useEffect with [opened]", opened)
    localStorage.setItem(STORAGE_KEY, JSON.stringify({ opened }))
  }, [opened])

  return <UserContext.Provider value={{ opened, hasOpen, addOpen }}>{props.children}</UserContext.Provider>
}

export default UserContextProvider
